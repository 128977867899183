/* Bootstrap override preventing usage of custom fonts */
html {
    font-size: inherit;
}

.ugc-item {
    width: calc(50% - .5rem);
    padding-bottom: calc(50% - .5rem);
    margin: 0.25rem;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;
    letter-spacing: 0.05rem;
    overflow: hidden;
}

.ugc-carousel {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding: 0 1rem;
}

.ugc-carousel .ugc-item {
    width:80%;
    white-space: normal;
    padding-bottom: 0;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    transform: scale(0.90);
    transition: all 400ms ease;
}

.ugc-carousel-wrapper {
    position: relative;
    transition: left 400ms ease;
}

.ugc-carousel .ugc-item.selected {
    transform: scale(1);
}

.ugc-moderators-comment {
    display: none;
}

.ugc-carousel .ugc-moderators-comment {
    display: block;
    padding: 1rem;
    letter-spacing: normal;
    background-color: white;
    text-align: center;
    position: relative;
}

.ugc-carousel .ugc-moderators-comment h5 {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
    margin: 1.25rem 0 0.25rem 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ugc-carousel .ugc-moderators-comment p {
    color: black;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    height: 36px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ugc-image {
    background-image:url('../imgs/monj-square.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ugc-carousel .ugc-image {
    position: relative;
}

.ugc-right-arrow {

}

.ugc-arrow-container {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    visibility: hidden;
    left: 0;
    width: 100%;
    z-index: 997;
    transition: all 400ms ease;
}

.ugc-arrow {
    width: 56px;
    height: 56px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 100%;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.5);
    margin: 0 0.5rem;
    cursor: pointer;
    transition: opacity 400ms ease;
    opacity: 1;
}

.ugc-arrow.left {
    background-image: url('../imgs/arrow-left-black.svg');
    float: left;
    visibility: visible;
    background-position: 82%;
    position: relative;
    left: -32px;
}

.ugc-arrow.right {
    background-image: url('../imgs/arrow-right-black.svg');
    float: right;
    visibility: visible;
    background-position: 18%;
    position: relative;
    right: -32px;
}

.ugc-arrow.hide {
    opacity: 0;
    visibility: hidden;
}

.ugc-popup-image {
    background-image:url('../imgs/monj-square.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative;
}

.ugc-play-button {
    opacity: 0.8;
    transition: opacity 400ms ease;
}

.ugc-play-button svg {
    display: block;
    position: absolute;
    opacity: 1;
    z-index:10;
    cursor: pointer;
}

.ugc-video-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.ugc-popup-video {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-bottom: 0;
    display: block;
    position: relative;
    cursor: pointer;
}

.ugc-like {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 17px;
    width: 20px;
    background-image: url('../imgs/Heart_empty.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: opacity 800ms ease;
    cursor: pointer;
}

.ugc-like.liked {
    background-image: url('../imgs/Heart_fill.svg');
}

.ugc-like p {
    width: 30px;
    position: relative;
    right: 5px;
    top: 1rem;
    font-size: .75rem;
    color: white;
    font-weight: 100;
}

.ugc-like.active {
    opacity: 1;
    visibility: visible;
    left: 8px;
    right: auto;
}

.ugc-text {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    color: white;
    font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    opacity: 0;
    transition: all 200ms ease;
    transform: scale(0.75);
    z-index:10;
}

.ugc-text p {
    margin: 1rem;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    width: 90%;
    max-width: 300px;
    font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.ugc-item:hover .ugc-text {
    opacity: 1;
    transform: scale(1);
}

.ugc-item:hover .ugc-like {
    opacity: 1;
    visibility: visible;
}

#ugc-modal-background {
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    cursor: pointer;
}

#ugc-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: left;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 90%;
    height: 90%;
    max-width: 1024px;
    max-height: 768px;
    z-index: 9999;
    overflow: hidden;
    overflow-y: auto;
    font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;
    letter-spacing: 0.05rem;
}

.ugc-profile-image {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    border: 2px solid white;
    float: left;
    display: inline-block;
    vertical-align: top;
    margin: 0 1rem 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../imgs/usericon-green.svg');
    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);
}

.ugc-profile-image.featured {
    display: none;
}

.ugc-carousel .ugc-moderators-comment .ugc-profile-image.featured {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -36px;
    z-index: 30;
    background-color: white;
}

.ugc-information-container {
    margin: 1rem;
}

.ugc-information-container hr {
    border-top: 1px solid rgba(0,0,0,0.15);
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin-bottom: 1rem;
}

.ugc-information-container h1{
    margin: 0;
}
.ugc-information-container h1, .ugc-information-container h3 {
    width: calc(100% - (75px + 2rem));
    padding: 0;
    display:inline-block;
    vertical-align: top;
}

.ugc-information-container h3 {
    color: gray;
    font-size: 1rem;
    margin: 0 0 1rem 0;
}

.ugc-close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50px;
    text-align: center;
    width: 50px;
    cursor: pointer;
    border: 0;
    outline: 0;

    background-image: url('../imgs/x-white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

/* Loading animation provided by http://tobiasahlin.com/spinkit/ */

.ugc-spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.ugc-spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ugc-spinner .ugc-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ugc-spinner .ugc-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

#ugc-loading.ugc-item {
    padding-bottom: 100px;
    cursor: default;
}
#ugc-loading {
    visibility: hidden;
}
#ugc-loading.active {
    visibility: visible;
}
/* End Loading - Spin Kit */

@media only screen and (min-width: 530px) {
    .ugc-item {
        width: calc(50% - .5rem);
        padding-bottom: calc(50% - .5rem);
        margin: 0.25rem;
    }
    .ugc-carousel .ugc-item {
        width: calc(50% - .5rem);
    }
}

@media only screen and (min-width: 769px) {
    .ugc-item {
        width: calc(33.3% - .5rem);
        padding-bottom: calc(33.3% - .5rem);
    }
    .ugc-carousel .ugc-item {
        width: calc(33.3% - .5rem);
        transform: scale(1);
    }

    #ugc-popup {
        height: auto;
    }

    .ugc-popup-image {
        width: 50%;
        padding-bottom: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .ugc-video-container {
        position: relative;
        display: inline-block;
        width: 50%;
    }

    .ugc-information-container {
        width: calc(50% - 2rem);
        display: inline-block;
        vertical-align: top;
    }

    .ugc-close-button {
        background-image: url('../imgs/x-black.svg');
    }

    .ugc-like.active {
        right: 8px;
        left: auto;
    }
}

@media only screen and (min-width: 1025px) {
    .ugc-item {
        width: calc(25% - .5rem);
        padding-bottom: calc(25% - .5rem);
    }
    .ugc-carousel .ugc-item {
        width: calc(25% - .5rem);
    }
}